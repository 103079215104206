import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Footer from "../components/Footer";
import Ccby from "../components/Ccby";
import { HTMLContent } from "../components/Content";
import Url from "../components/Url";
import Img from "gatsby-image";

export const IndexPageTemplate = ({
  banner_image,
  usp_image_1,
  usp_image_2,
  usp_image_3,
  title,
  //contentComponent,
}) => {
  //const PageContent = contentComponent || Content;

  return (
    <div>
      <section className="hero">
        <div className="hero-body">
          <Img fluid={banner_image.image.childImageSharp.fluid} />
          <br />
          <div className="has-text-right pr-4 is-size-7 has-text-grey">
            Photo by{" "}
            <Url
              url={banner_image.attribution_link}
              alt={banner_image.attribution_alt}
              text={banner_image.attribution_text}
            />{" "}
            on{" "}
            <Url
              url={banner_image.location_link}
              alt={banner_image.location_alt}
              text={banner_image.location_text}
            />
          </div>
        </div>
      </section>
      <section className="section pt-0 section--gradient">
        <div className="container pt-0">
          <div className="columns pt-0">
            <div className="column is-10 pt-0 is-offset-1">
              <div className="section pt-0">
                <h1 className="title is-size-3 pt-0 has-text-weight-bold is-bold-light">
                  {title}
                </h1>
                <h2 className="subtitle">
                  The adventures of teddys and their people
                </h2>
                <div className="columns">
                  <div className="column is-one-third">
                    <div className="card card-equal-height">
                      <div className="card-image">
                        <img
                          src={`${usp_image_1.publicURL}`}
                          alt="Bedtime Stories"
                        />
                      </div>
                      <div className="card-content">
                        <p className="block">
                          Join the teddys of Teddy Story on their adventures.
                        </p>
                        <p className="block">
                          Teddys and their companions can turn up anywhere, at
                          any time. Find out where they are today.</p>
                        <Link className="button has-text-link" to="/stories/"> Read the stories </Link>
                      </div>
                    </div>
                  </div>
                  <div className="column is-one-third">
                    <div className="card card-equal-height">
                      <div className="card-image">
                        <img src={`${usp_image_2.publicURL}`} alt="Customise" />
                      </div>
                      <div className="card-content">
                        <p className="block">Read your way. </p>
                        <p className="block">
                          Customise the stories to suit you and your audience.
                        </p>
                        <p className="block">
                          Available where you see the Custom Main Character
                          button.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="column is-one-third">
                    <div className="card card-equal-height">
                      <div className="card-image">
                        <img
                          src={`${usp_image_3.publicURL}`}
                          alt="Bonus Content"
                        />
                      </div>
                      <div className="card-content">
                        <p className="block">
                          The stories are all free to read on Teddy Story.
                        </p>
                        <p className="block">
                          Find extra chapters and bonus content if you are a
                          Coil member.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <section className="block">
                  <h2 className="title is-size-4 pt-0 has-text-weight-bold is-bold-light">
                    What is Teddy Story?
                  </h2>
                  <p className="block">
                    Teddy Story is a collection of bedtime stories about the
                    adventures of teddys and their companions.
                  </p>
                  <p className="block">
                    {" "}
                    The teddys in the stories aren&apos;t just teddy bears or
                    toys; they are friends and companions of all shapes and
                    sizes. Anything can qualify as a teddy.
                  </p>
                  <p className="block">
                    Suitable for all ages, each instalment takes less than 10
                    minutes to read aloud. Perfect for bedtime.
                  </p>
                </section>
                <section className="block">
                  <h2 className="title is-size-4 pt-0 has-text-weight-bold is-bold-light">
                    Read your way
                  </h2>
                  <p className="block">
                    Audiences are different, so the stories adapt. If you are
                    reading to audiences that would rather hear about female
                    characters, Penny&apos;s for you. Maybe they prefer male
                    characters? Meet Max. Perhaps you&apos;d like something more
                    personal?
                  </p>
                  <p className="block">
                    {" "}
                    For complete control, choose the Custom option and take
                    charge of the characters&apos; names and genders. Reading to your
                    whole class? Name the main character Class 15 and set the
                    gender to neutral; the story will still work.
                  </p>
                  <p className="block">
                    <em>Read your way.</em>
                  </p>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  banner_image: PropTypes.shape({
    banner_image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    attribution_alt: PropTypes.string,
    attribution_text: PropTypes.string,
    attribution_link: PropTypes.string,
    location_alt: PropTypes.string,
    location_text: PropTypes.string,
    location_link: PropTypes.string,
  }),
  usp_image_1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  usp_image_2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  usp_image_3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const IndexPage = ({ data }) => {
  const { mdx: post } = data;

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.banner_image.image}
        paymentpointer={post.frontmatter.paymentpointer}
      />
      <IndexPageTemplate
        image={post.frontmatter.image}
        banner_image={post.frontmatter.banner_image}
        usp_image_1={post.frontmatter.usp_image_1}
        usp_image_2={post.frontmatter.usp_image_2}
        usp_image_3={post.frontmatter.usp_image_3}
        title={post.frontmatter.title}
        content={post.body}
        contentComponent={HTMLContent}
      />
      <Footer>
        <Ccby />
      </Footer>
    </Layout>
  );
};

IndexPage.propTypes = {
  //data: PropTypes.object.isRequired,
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    mdx(frontmatter: { templateKey: { eq: "index-page" } }) {
      body
      frontmatter {
        title
        description
        paymentpointer
        usp_image_1 {
          publicURL
        }
        usp_image_2 {
          publicURL
        }
        usp_image_3 {
          publicURL
        }
        banner_image {
          attribution_alt
          attribution_text
          attribution_link
          location_alt
          location_text
          location_link
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
