import React from 'react'
import ccby from '../img/cc-by-88x31.png'

// Creative Commons by Attribution License
// default license
// will be overridden on writing pages

const Ccby = () => {
  return (
    <p>
      This page is licensed under a<br />
      <a target="_blank" rel="noopener noreferrer license" href="http://creativecommons.org/licenses/by/4.0/">
        Creative Commons Attribution 4.0 International License
      </a>
      .<br />
      <a target="_blank" rel="noopener noreferrer license" href="http://creativecommons.org/licenses/by/4.0/">
        <img alt="Creative Commons License" src={ccby} />
      </a>
    </p>
  )
}

export default Ccby
